<template>
    <section class="vh-100" style="background: #F5F9FF;">
        <b-img class="position-absolute m-2" src="@/assets/images/logo-horizontal.svg" alt="logo" />
        <div class="container w-md-50 h-100">



            <div class="h-100 d-flex flex-column align-items-center justify-content-center">

                <div class="d-flex align-items-center mb-2 align-self-start">
                    <b-img class="mr-1" height="50" src="@/assets/icons/waving-hand.svg"></b-img>
                    <h2 class="text-primary-light mb-0">Bentornato in AutoinCloud!</h2>
                </div>


                <div class="w-100 bg-white border border-2 border-primary mb-4 rounded-sm">
                    <div class="bg-secondary-light py-1 px-2 rounded-top">
                        <span class="text-primary font-weight-bold">Aree di lavoro per {{ current_user.user_name }} {{
                        current_user.user_surname }}</span>
                    </div>

                    <div v-for="workspace in workspaces" :key="workspace_id" id="company_box"
                        class="border-bottom-secondary d-flex align-items-center justify-content-between bg-white px-2 py-1">
                        <div>
                            <div class="d-flex">
                                <b-img height="30" class="bg-primary-light"></b-img>
                                <div>
                                    <p class="text-primary-light font-weight-bolder">{{ workspace.workspace_name }}</p>
                                    <span>{{ workspace.role_name }}</span>
                                </div>
                            </div>
                        </div>

                        <b-button class="text-uppercase" variant="primary" @click="setWorkspace(workspace)">
                            Accedi
                        </b-button>
                    </div>
                </div>

                <div
                    class="w-100 d-flex justify-content-between align-items-center border border-2 border-primary rounded-sm p-2 bg-white">
                    <span class="text-primary font-weight-bolder">Vuoi utilizzare AutoinCloud con un'altra
                        azienda?</span>
                    <b-button class="text-uppercase border-2 font-weight-bolder" variant="outline-primary"
                        @click="$router.push('/workspaces/create')">Crea una nuova area di lavoro</b-button>
                </div>
            </div>


        </div>


    </section>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import { Requests } from '@/api';

export default {
    components: {
        BButton,
        BImg,
    },

    data: () => {
        return {
            workspaces: [],
            current_user: {}
        }
    },

    mounted() {
        this.getMyWorkspace();
        if (this.workspaces.length == 1) {
            this.setWorkspace(this.workspaces[0]);
            this.$router.push('/dashboard');
        }
        this.current_user = JSON.parse(localStorage.getItem('user'));
    },

    methods: {
        async getMyWorkspace() {
            const response = await Requests.getMyWorkspaces();
            this.workspaces = response.data;
        },

        setWorkspace(workspace) {
            localStorage.setItem("workspace", JSON.stringify(workspace));
            this.$router.push('/dashboard');
        }
    }
}

</script>

<style>
#company_box:last-child {
    border: none;
    border-radius: 1% !important;
}
</style>
